import type * as Fabric from '@postermywall/fabricjs-2';
import {CustomBorderTable, FixedLayout, LayoutManager, util} from '@postermywall/fabricjs-2';
import {ITEM_TYPE} from '@PosterWhiteboard/items/item/item.types';
import type {RGB} from '@Utils/color.util';
import type {OnResizeParams} from '@PosterWhiteboard/poster/poster-item-controls';
import {getPmwMlControl, getPmwMrControl} from '@PosterWhiteboard/poster/poster-item-controls';
import type {TableItemObject} from '@PosterWhiteboard/items/table-item/table-item.class';
import {TableItem} from '@PosterWhiteboard/items/table-item/table-item.class';
import {cacheIcon, loadedIcons} from '@PosterWhiteboard/items/menu-item/menu-icons';
import {degreesToRadians} from '@Utils/math.util';
import {openEditMenuModal} from '@Components/poster-editor/library/poster-editor-open-modals';
import {prepareDataForMenuItem} from '@PosterWhiteboard/items/menu-item/user-menu';
import type {MenuItemData} from '@Panels/user-menu-panel/user-menu-panel.types';
import {BorderType} from '@PosterWhiteboard/classes/item-border.class';
import {LayoutTypes} from '@PosterWhiteboard/items/layouts/layout.types';
import type {MenuData} from '@Libraries/add-media-library';
import {ElementDataType} from '@Libraries/add-media-library';
import editIconSVG from '@PosterWhiteboard/poster/poster-item-control-svgs/edit-icon.svg';
import type {LoadSVGDataResponse} from '@Utils/fabric.util';

export const DEFAULT_FONT_FAMILY = 'OpenSansRegular';
export const DEFAULT_FONT_FAMILY2 = 'Abel';
export const MENU_LAYOUTS = [
  LayoutTypes.MENU_LAYOUT_1,
  LayoutTypes.MENU_LAYOUT_2,
  LayoutTypes.MENU_LAYOUT_3,
  LayoutTypes.MENU_LAYOUT_4,
  LayoutTypes.MENU_LAYOUT_5,
  LayoutTypes.MENU_LAYOUT_6,
];

export interface MenuItemObject extends TableItemObject {
  itemIds: Array<string>;
  copiedItemIds: Array<string>;
  iconsColor: RGB;
  iconsSize: number;
  wrappingInfo: Array<Array<string>> | null;
  // totalIconsToLoad: number;
  // numIconsLoaded: number;
}

export class MenuItem extends TableItem {
  declare fabricObject: Fabric.CustomBorderTable;
  public gitype = ITEM_TYPE.MENU;
  public itemIds: Array<string> = [];
  public copiedItemIds: Array<string> = [];
  public iconsColor: RGB = [0, 0, 0];
  public iconsSize = 0;
  public wrappingInfo: Array<Array<string>> | null = null;
  public totalIconsToLoad = 0;
  public numIconsLoaded = 0;

  protected setControlsVisibility(): void {
    super.setControlsVisibility();
    const isItemLocked = this.isLocked();
    this.fabricObject.setControlsVisibility({
      pmwMr: !isItemLocked,
      pmwMl: !isItemLocked,
    });
  }

  protected async getFabricObjectForItem(): Promise<CustomBorderTable> {
    return new Promise((resolve) => {
      resolve(
        new CustomBorderTable([], {
          objectCaching: false,
          perPixelTargetFind: true,
          pmwBmBtnText: window.i18next.t('pmwjs_edit_item'),
          pmwBmBtnIcon: editIconSVG as string,
          layoutManager: new LayoutManager(new FixedLayout()),
        })
      );
    });
  }

  protected initCustomControls(): void {
    super.initCustomControls();

    const pmwMlControl = getPmwMlControl((event) => {
      void this.onResizeWithLeftHandle(event);
    });
    const pmwMrControl = getPmwMrControl((event) => {
      void this.onResizeWithRightHandle(event);
    });

    this.fabricObject.controls[pmwMlControl.key] = pmwMlControl.control;
    this.fabricObject.controls[pmwMrControl.key] = pmwMrControl.control;
  }

  protected editItem(): void {
    openEditMenuModal(this);
  }

  protected onItemDoubleClicked(): void {
    openEditMenuModal(this);
  }

  public updateFromMenuData(updatedSelectedItems: Array<MenuItemData>, layout: LayoutTypes): void {
    const changes = prepareDataForMenuItem(updatedSelectedItems, layout);
    changes.border = {
      ...this.border,
      solidBorderType: layout === LayoutTypes.MENU_LAYOUT_4 && !this.border.solidBorderType ? BorderType.RECTANGLE_BORDER : this.border.solidBorderType,
    };

    if (changes.itemIds?.length === 0) {
      this.page.poster.deleteItemById(this.uid);
    } else {
      void this.updateFromObject(changes);
    }
  }

  protected async updateFabricObject(): Promise<void> {
    await super.updateFabricObject();
    this.invalidateIcons();
    // Width of the viewComponent is determined programmatically when 'doLayout' function is called,
    // hence we need to update the model_.width after item is rendered on canvas.
    this.width = this.fabricObject.width;
  }

  /**
   * Invalidates list of required icons for this menu graphic item.
   */
  invalidateIcons(): void {
    let i;
    const icons = this.layoutDataMap.iconsList;
    let iconsToLoad: Array<string> = [];
    if (icons) {
      for (i = 0; i < icons.length; i++) {
        const iconsList = icons[i].getValue() as Array<string>;
        for (let j = 0; j < iconsList.length; j++) {
          if (!(iconsList[j] in loadedIcons)) {
            iconsToLoad.push(iconsList[j]);
          }
        }
      }
    }

    // removing duplicates from iconsToLoad array.
    iconsToLoad = iconsToLoad.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });

    this.totalIconsToLoad = iconsToLoad.length;
    this.numIconsLoaded = 0;

    for (i = 0; i < iconsToLoad.length; i++) {
      cacheIcon(iconsToLoad[i])
        .then(this.onIconLoaded.bind(this, iconsToLoad[i]))
        .catch((e) => {
          console.error(e);
        });
    }
  }

  /**
   * Callback function to use when icon's svg file is loaded from the server
   * @private
   */
  onIconLoaded(name: string, svgData: LoadSVGDataResponse): void {
    // group svg elements and cache the fabric.Path object representing the icon
    this.numIconsLoaded += 1;
    if (svgData.paths) {
      loadedIcons[name] = util.groupSVGElements(svgData.paths, {
        ...svgData.metaData,
        selectable: false,
        scaleX: 1,
        scaleY: 1,
        left: -svgData.metaData.width / 2,
        top: -svgData.metaData.height / 2,
      });
    }
    if (this.totalIconsToLoad === this.numIconsLoaded) {
      this.updateFabricObject().catch(() => {});
      this.fabricObject.setCoords();
    }
    this.page.fabricCanvas.requestRenderAll();
  }

  async onResizeWithRightHandle(event: OnResizeParams): Promise<void> {
    const offset = event.delta / this.fabricObject.scaleX;
    if (this.xSpacing + offset < 0) {
      return;
    }

    this.xSpacing += offset;
    await this.refreshView();
  }

  async onResizeWithLeftHandle(event: OnResizeParams): Promise<void> {
    const offset = event.delta / this.fabricObject.scaleX;

    if (this.xSpacing + offset < 0) {
      return;
    }

    this.xSpacing += offset;
    await this.refreshView();
    this.fabricObject.set({
      left: this.fabricObject.left - event.delta * Math.cos(degreesToRadians(this.fabricObject.angle)),
      top: this.fabricObject.top - event.delta * Math.sin(degreesToRadians(this.fabricObject.angle)),
    });
  }

  /**
   * Returns an array of arrays containing wrapped lines of text by the view.
   * @returns {Array} Can be null if no data is present.
   */
  getWrappedLines(): Array<Array<string>> {
    return this.layout.getWrappingInfo();
  }

  public toObject(): MenuItemObject {
    return {
      ...super.toObject(),
      itemIds: this.itemIds,
      copiedItemIds: this.copiedItemIds,
      iconsColor: this.iconsColor,
      iconsSize: this.iconsSize,
      wrappingInfo: this.wrappingInfo,
    };
  }

  updateCopiedItems(items: Array<string>): void {
    this.copiedItemIds = items;
  }

  public getChangesOnMenuLayoutChange(layout: LayoutTypes): Partial<MenuItemObject> {
    return {
      layoutStyle: layout,
      border: {
        ...this.border,
        solidBorderType: layout === LayoutTypes.MENU_LAYOUT_4 ? BorderType.RECTANGLE_BORDER : BorderType.NONE,
      },
    };
  }

  public updateMenuLayout(layout: LayoutTypes): void {
    void this.updateFromObject(this.getChangesOnMenuLayoutChange(layout));
  }

  public updateIconsColor(iconsColor: RGB, undoable = true): void {
    void this.updateFromObject(
      {
        iconsColor,
      },
      {
        undoable,
      }
    );
  }

  protected onItemDoubleTapped(): void {
    openEditMenuModal(this);
  }
}

export const addMenuToPoster = (menuItems: Array<MenuItemData>, layout: string): void => {
  const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
  if (!currentPage) {
    return;
  }
  void currentPage.items.addItems.addMenuItem(
    {
      type: ElementDataType.MENU,
      ...prepareDataForMenuItem(menuItems, layout),
    } as MenuData,
    {},
    true
  );
};
