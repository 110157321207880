import {openPanelInModal} from '@Components/modal-container/modal-container-reducer';
import {MODALS_IDS} from '@Components/modal-container';
import type {PosterPublishingOptionsPanelProps} from '@Panels/poster-publishing-options-panel/poster-publishing-options-panel.types';

export const openPublishingOptionsModal = (props: PosterPublishingOptionsPanelProps): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.PUBLISHING_OPTIONS_MODAL,
      panelProps: props,
    })
  );
};
