import type {PageBackgroundObject} from '@PosterWhiteboard/page/page-background.class';
import type * as Fabric from '@postermywall/fabricjs-2';
import type {PageItemsObject} from '@PosterWhiteboard/page/page-items.class';
import type {PageAnimationObject} from '@PosterWhiteboard/page/page-animation.class';

export const MAX_ALLOWED_GRAPHIC_ITEMS = 300;
export const MAX_VIDEO_ITEMS = 4;
export const MAX_STICKER_ITEMS = 10;

export interface PageObject {
  hashedID: string;
  items: PageItemsObject;
  background: PageBackgroundObject;
  introAnimation: PageAnimationObject;
  duration: number;
}

export interface ContextMenuStoppedEventData {
  e: MouseEvent;
  target: Fabric.Object | undefined;
}

export const DEFAULT_BACKGROUND_COLOR = 'ffffff';
export const DEFAULT_SECONDARY_BACKGROUND_COLOR = '000000';
export const MAX_ITEM_ANIMATION_DELAY_FACTOR = 0.3; // Remember to change its counter part in php postervo variable value too
export const MAX_ANIMATION_DURATION = 4;
export const FIXED_SEQUENTIAL_ANIMATION_DELAY_FACTOR = 0.4;
export const MAX_ITEMS_WITH_FIXED_DELAY_FACTOR = 5;
